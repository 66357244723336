import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Grid from '@material-ui/core/Grid';

class UsingAs extends React.Component {
  render() {
    return (
      <div>
        <h5 className="using-as-form-label" style={{ marginTop:-20, color:'#828282' }}>{this.props.label}</h5>
        <div className="using-as-wrapper">
          {!this.props.skip_business &&
            <div className="using-as">
              <input type="radio" name={`${this.props.form}[using_as]`} id="business" value="business" onChange={this.props.onChange} defaultChecked={this.props.value == 'business'} />
              <label htmlFor="business">
                <Grid
                  container
                  className="using-as-container"
                  direction="column"
                  justify="center"
                  alignItems="center">
                  <Grid item>
                    <SvgIcon viewBox="0 0 60 60">
                      <g>
                        <path className="st0" d="M14.1,42h7.5"/>
                        <path className="st0" d="M14.1,36.5h7.5"/>
                        <path className="st0" d="M14.1,47.6h7.5"/>
                        <path className="st0" d="M14.1,30.9h7.5"/>
                        <path className="st0" d="M26.2,17V5l26.2,9.3V55h-45V23.5h20.6v30.8"/>
                        <path className="st0" d="M34.6,48.5V17"/>
                        <path className="st0" d="M40.4,48.5V18.9"/>
                        <path className="st0" d="M46.2,48.5V20.7"/>
                      </g>
                    </SvgIcon>
                  </Grid>
                  <Grid item>
                    Business
                  </Grid>
                </Grid>
              </label>
            </div>
          }

          <div className="using-as">
            <input type="radio" name={`${this.props.form}[using_as]`} id="student" value="student" onChange={this.props.onChange} defaultChecked={this.props.value == 'student'} />
            <label htmlFor="student">
              <Grid
                container
                className="using-as-container"
                direction="column"
                justify="center"
                alignItems="center">
                <Grid item>
                  <SvgIcon viewBox="0 0 60 60">
                    <g>
                      <path class="st0" d="M10.6,23v23c0,5,8.8,9,19.7,9s19.7-4,19.7-9V23"/>
                      <path class="st0" d="M39.8,38.3l4.9,7h-9.8L39.8,38.3l0-10.2"/>
                      <path class="st0" d="M30.2,33l-27.5-14L30.2,5l27.5,13.9L30.2,33z"/>
                    </g>
                  </SvgIcon>
                </Grid>
                <Grid item>
                  Student
                </Grid>
              </Grid>
            </label>
          </div>

          <div className="using-as">
            <input type="radio" name={`${this.props.form}[using_as]`} id="educator" value="educator" onChange={this.props.onChange} defaultChecked={this.props.value == 'educator'} />
            <label htmlFor="educator">
              <Grid
                container
                className="using-as-container"
                direction="column"
                justify="center"
                alignItems="center">
                <Grid item>
                  <SvgIcon viewBox="0 0 60 60">
                    <g>
                      <line class="st0" x1="20" y1="20.8" x2="33.3" y2="20.8"/>
                      <line class="st0" x1="20" y1="30.8" x2="40" y2="30.8"/>
                      <line class="st0" x1="30" y1="43.3" x2="30" y2="53.3"/>
                      <line class="st0" x1="25" y1="43.3" x2="15" y2="55"/>
                      <line class="st0" x1="35" y1="43.3" x2="45" y2="55"/>
                      <polyline class="st0" points="50,38.3 50,9.2 10,9.2 10,38.3 	"/>
                      <line class="st0" x1="55" y1="43.3" x2="5" y2="43.3"/>
                      <line class="st0" x1="30" y1="9.2" x2="30" y2="5"/>
                      <line class="st0" x1="53.3" y1="5.8" x2="40" y2="19.2"/>
                    </g>
                  </SvgIcon>
                </Grid>
                <Grid item>
                  Educator
                </Grid>
              </Grid>
            </label>
          </div>

          <div className="using-as">
            <input type="radio" name={`${this.props.form}[using_as]`} id="individual" value="individual" onChange={this.props.onChange} defaultChecked={this.props.value == 'individual'} />
            <label htmlFor="individual">
              <Grid
                container
                className="using-as-container"
                direction="column"
                justify="center"
                alignItems="center">
                <Grid item>
                  <SvgIcon viewBox="0 0 60 60">
                    <g>
                      <ellipse class="st0" cx="30" cy="18.1" rx="13.1" ry="13.1"/>
                      <path class="st0" d="M52.5,55v-1.3c0-12.5-10.2-22.5-22.5-22.5S7.5,41.1,7.5,53.6v1.3"/>
                    </g>
                  </SvgIcon>
                </Grid>
                <Grid item>
                  Individual
                </Grid>
              </Grid>
            </label>
          </div>
        </div>
        {this.props.error && this.props.error.length > 0 &&
          <span className="help-block">{this.props.error}</span>
        }
      </div>
    );
  }
}

export default UsingAs;
