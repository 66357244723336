import React from "react";
import { Input } from "./Input";

const ButtonInput = ({ label, value, hint = "", onChange }) => (
  <div className="form-group custom-button-input">
    {label && <label className="control-label">{label}</label>}

    <div className="fields">
      <Input
        label="Label"
        type="text"
        value={value.label || ""}
        hint={hint}
        onChange={(val) => onChange({ ...value, label: val })}
      />

      <Input
        label="ID"
        type="text"
        value={value.id || ""}
        onChange={(val) => onChange({ ...value, id: val })}
      />
    </div>
  </div>
);

export default ButtonInput;
