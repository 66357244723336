import React from 'react';

class Company extends React.Component {
  render() {
    return (
      <div className="company-wrapper">
        {this.props.label &&
          <label htmlFor={`${this.props.form}_company`}>{this.props.label}</label>
        }

        <input onChange={this.props.onChange}
              type="text"
              value={this.props.value}
              name={`${this.props.form}[company]`}
              id={`${this.props.form}_company`}
              aria-invalid={!!this.props.error}
              autoComplete="off" />
        {this.props.error && this.props.error.length > 0 &&
          <span className="help-block">{this.props.error}</span>
        }

        {this.props.valid && !this.props.error &&
          <div className="check active"></div>
        }
      </div>
    );
  }
}

export default Company;
