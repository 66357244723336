import React from 'react';

class Button extends React.Component {
  render() {
    return(
      <div className="trial-button-wrapper mt20">
        <button onClick={this.props.onClick}
                type="button"
                className={'btn-active ' + (this.props.loading ? 'blue' : '')}
                disabled={this.props.disabled}
                id={this.props.id}>
          {this.props.loading ? <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div> : this.props.value}
        </button>
      </div>
    );
  }
}

export default Button;
