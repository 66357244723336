import React from "react";
import { Input } from "./Input";
import { ListInput } from "@raketa-cms/raketa-cms";
import { AdminRTE } from "@chaos/website-frontend";
import ButtonInput from "./ButtonInput";

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <AdminRTE
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

const BenefitsInput = ({ value, onChange }) => {
  return (
    <div className="field-pannel form-group">
      <ListInput
        label="Benefits"
        listItem={(settings, onChangeItem, idx) => (
          <ListItem idx={idx} settings={settings} onChangeItem={onChangeItem} />
        )}
        primaryField="description"
        onChangeList={(_, newItems) => onChange(newItems)}
        items={value}
        template={{ id: "", description: "" }}
      />
    </div>
  );
};

const UpsellSectionInput = ({ value, name }) => {
  const [state, setState] = React.useState(value);

  function updateState(key, val) {
    setState((currrent) => ({
      ...currrent,
      [key]: val,
    }));
  }

  return (
    <div>
      <Input
        type="text"
        value={state.rate_plan_id || ""}
        label="Upsell Product (Rate Plan ID)"
        onChange={(val) => updateState("rate_plan_id", val)}
      />

      <Input
        type="text"
        value={state.title || ""}
        label="Section Title"
        hint="Supports markdown"
        onChange={(val) => updateState("title", val)}
      />

      <Input
        type="text"
        value={state.card_title || ""}
        label="Card Title"
        hint="You can use the tokens {ANNUAL_SAVINGS} and {MONTHLY_SAVINGS}"
        onChange={(val) => updateState("card_title", val)}
      />

      <Input
        type="text"
        value={state.card_sub_title || ""}
        label="Card Sub Title"
        hint="Supports markdown"
        onChange={(val) => updateState("card_sub_title", val)}
      />

      <Input
        type="text"
        value={state.benefits_title || ""}
        label="Benefits title"
        onChange={(val) => updateState("benefits_title", val)}
      />

      <BenefitsInput
        value={state.benefits || []}
        onChange={(val) => updateState("benefits", val)}
      />

      <Input
        type="text"
        value={state.card_disclaimer || ""}
        label="Card Disclaimer"
        hint="You can use the tokens {PER_YEAR} and {PER_MONTH}"
        onChange={(val) => updateState("card_disclaimer", val)}
      />

      <ButtonInput
        label="Upsell Button"
        value={state.upsell_button || {}}
        onChange={(val) => updateState("upsell_button", val)}
      />

      <ButtonInput
        label="Upsell Button (pressed)"
        value={state.upsell_button_pressed || {}}
        hint="The button label supports the {CHECK} token"
        onChange={(val) => updateState("upsell_button_pressed", val)}
      />

      <ButtonInput
        label="Continue Button"
        value={state.continue_button || {}}
        onChange={(val) => updateState("continue_button", val)}
      />

      <input
        className="form-control"
        type={"hidden"}
        name={name}
        defaultValue={JSON.stringify(state)}
      />
    </div>
  );
};

export default UpsellSectionInput;
