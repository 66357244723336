import React from "react";
import { Input, Textarea } from "./Input";
import ButtonInput from "./ButtonInput";
import {
  ImagePicker,
  MediaManagerContext,
} from "@raketa-cms/raketa-image-picker";
import { RaketaUIProvider } from "@raketa-cms/raketa-cms";
import MediaManager from "components/cms/MediaManager";

const mediaManager = new MediaManager("/");

const ProductInput = ({ label, value, onChange }) => {
  function updateValue(key, val) {
    onChange({
      ...value,
      [key]: val,
    });
  }

  return (
    <>
      <h4>{label}</h4>
      <Input
        type="text"
        value={value.rate_plan_id || ""}
        label="Cross-sell Product (Rate Plan ID)"
        onChange={(val) => updateValue("rate_plan_id", val)}
      />

      <Input
        type="text"
        value={value.name || ""}
        label="Name"
        onChange={(val) => updateValue("name", val)}
      />
      <RaketaUIProvider>
        <MediaManagerContext.Provider value={mediaManager}>
          <ImagePicker
            onChange={(val) => updateValue("logo", val)}
            value={value.logo}
          />
        </MediaManagerContext.Provider>
      </RaketaUIProvider>

      <Textarea
        value={value.description || ""}
        label="Description"
        onChange={(val) => updateValue("description", val)}
      />

      <ButtonInput
        label="Upsell Button"
        value={value.button || {}}
        onChange={(val) => updateValue("button", val)}
      />

      <ButtonInput
        label="Upsell Button (added)"
        value={value.button_added || {}}
        hint="The button label supports the {CHECK} token"
        onChange={(val) => updateValue("button_added", val)}
      />
    </>
  );
};

const CrossSellSectionInput = ({ value, name }) => {
  const [state, setState] = React.useState(value);

  function updateState(key, val) {
    setState((currrent) => ({
      ...currrent,
      [key]: val,
    }));
  }

  return (
    <div>
      <Input
        type="text"
        value={state.title || ""}
        label="Section Title"
        hint="Supports markdown"
        onChange={(val) => updateState("title", val)}
      />

      <ButtonInput
        label="Continue Button"
        value={state.continue_button || {}}
        onChange={(val) => updateState("continue_button", val)}
      />

      <ProductInput
        label="Product 1"
        value={state.product_1 || {}}
        onChange={(val) => updateState("product_1", val)}
      />

      <ProductInput
        label="Product 2"
        value={state.product_2 || {}}
        onChange={(val) => updateState("product_2", val)}
      />

      <input
        className="form-control"
        type={"hidden"}
        name={name}
        defaultValue={JSON.stringify(state)}
      />
    </div>
  );
};

export default CrossSellSectionInput;
