import React from "react";
import {
  GraphQLProvider,
  AdminUserProvider,
  GeoIpProvider,
  PageUrlProvider,
  CurrencyProvider,
  FormsProvider,
  CaptchaProvider,
  CountriesProvider,
  CartProvider,
  LocaleProvider,
  HubSpotContext,
  ResellersContext,
  LocalizationUrlContext,
} from "@chaos/website-frontend";

const addToCart = async (a, b, c) => {
  const mod = await import("webshop");

  mod.addToCart(a, b, c);
};

const addToCartMultiple = async (products) => {
  const mod = await import("webshop");

  mod.addToCartMultiple(products);
};

const PageProvider = ({
  gql_url,
  geo_ip_country,
  country_groups,
  currency,
  is_admin,
  current_url,
  forms_context,
  captcha_context,
  all_countries,
  locale,
  hubspot,
  resellers,
  localization_url,
  children,
}) => (
  <div>
    <AdminUserProvider isAdmin={is_admin}>
      <LocalizationUrlContext.Provider value={localization_url}>
        <LocaleProvider locale={locale}>
          <GeoIpProvider country={geo_ip_country} allGroups={country_groups}>
            <PageUrlProvider url={current_url}>
              <GraphQLProvider gqlHost={gql_url}>
                <CurrencyProvider currency={currency}>
                  <FormsProvider formsContext={forms_context}>
                    <CaptchaProvider captchaContext={captcha_context}>
                      <CountriesProvider allCountries={all_countries}>
                        <CartProvider
                          cart={{
                            add: addToCart,
                            addMultiple: addToCartMultiple,
                          }}
                        >
                          <ResellersContext.Provider value={resellers}>
                            <HubSpotContext.Provider
                              value={{
                                api_domain: hubspot.api_domain,
                                property: hubspot.property,
                                http_referer: hubspot.http_referer,
                                contact_id: hubspot.contact_id,
                                csrf_token: hubspot.csrf_token,
                              }}
                            >
                              {children}
                            </HubSpotContext.Provider>
                          </ResellersContext.Provider>
                        </CartProvider>
                      </CountriesProvider>
                    </CaptchaProvider>
                  </FormsProvider>
                </CurrencyProvider>
              </GraphQLProvider>
            </PageUrlProvider>
          </GeoIpProvider>
        </LocaleProvider>
      </LocalizationUrlContext.Provider>
    </AdminUserProvider>
  </div>
);

export default PageProvider;
