import React from 'react';
import Company from './Company'
import Button from './Button'
import UsingAs from './UsingAs'

class PleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      using_as_value: props.using_as_value,
      using_as_error: props.errors.using_as_error,
      company_value: props.company_value,
      company_error: props.errors.company,
      btn_disabled: true,
      loading: false
    };

    this.handleUsingAsChange = this.handleUsingAsChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleUsingAsChange(e) {
    this.setState({
      using_as_value: e.target.value,
      using_as_error: null,
      company_value: '',
      company_error: null,
      btn_disabled: e.target.value != 'individual'
    });
  }

  handleCompanyChange(e) {
    let valid = e.target.value.length > 1
    this.setState({
      company_value: e.target.value,
      company_error: null,
      btn_disabled: !valid
    });
  }

  handleButtonClick() {
    this.setState({ btn_disabled: true, loading: true })
    document.getElementById('ple_form_apply').submit();
  }

  showCompanyField() {
    return this.state.using_as_value && this.state.using_as_value != 'individual'
  }

  render() {
    return (
      <div className="">
        <UsingAs label={this.props.using_as_label}
                 form="ple_form"
                 skip_business="true"
                 onChange={this.handleUsingAsChange}
                 value={this.state.using_as_value}
                 error={this.state.using_as_error} />
        {this.showCompanyField() &&
          <Company label={this.props.company_label}
                   form="ple_form"
                   onChange={this.handleCompanyChange}
                   value={this.state.company_value}
                   error={this.state.company_error}
                   valid={this.state.company_value.length > 1} />
        }
        <Button value={this.props.submit}
                disabled={this.state.btn_disabled}
                loading={this.state.loading}
                onClick={this.handleButtonClick}
                id={this.props.button_id} />
      </div>
    );
  }
}

export default PleForm;
