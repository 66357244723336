import React, { useState } from "react";
import { SelectMenu, TextInput, LinkSettings } from "@raketa-cms/raketa-cms";
import SelectOptions from "./SelectOptions";
import FileOptions from "./FileOptions";
import CheckboxesOptions from "./CheckboxesOptions";
import labelStyles from "./labelStyles";

const fieldTypes = [
  ["text", "Text"],
  ["email", "Email"],
  ["url", "Url"],
  ["file", "File"],
  ["hidden", "Hidden"],
  ["textarea", "Textarea"],
  ["select", "Select"],
  ["date", "Date"],
  ["country", "Country"],
  ["checkboxes", "Multiselect"],
  ["cg_atc_fee", "CG ATC annual fee"],
];

const defaultFileOptions = {
  maxFileSize: 10,
  types: [{ id: 1, extension: ".exe" }],
};

const showName = (fieldType) =>
  fieldType !== "country" && fieldType !== "cg_atc_fee";
const showPlaceholder = (fieldType) =>
  fieldType !== "checkboxes" &&
  fieldType !== "cg_atc_fee" &&
  fieldType !== "hidden";
const showPattern = (fieldType) =>
  fieldType !== "checkboxes" &&
  fieldType !== "country" &&
  fieldType !== "date" &&
  fieldType !== "file" &&
  fieldType !== "select" &&
  fieldType !== "cg_atc_fee" &&
  fieldType !== "hidden";
const showCharacterList = (fieldType) =>
  fieldType !== "checkboxes" &&
  fieldType !== "country" &&
  fieldType !== "date" &&
  fieldType !== "file" &&
  fieldType !== "select" &&
  fieldType !== "cg_atc_fee" &&
  fieldType !== "hidden";
const showSelectOptions = (fieldType) => fieldType === "select";
const showCheckboxesOptions = (fieldType) => fieldType === "checkboxes";
const showFileOptions = (fieldType) => fieldType === "file";

const showHint = (fieldType) =>
  fieldType !== "cg_atc_fee" && fieldType !== "hidden";

const showRequired = (fieldType) => fieldType !== "cg_atc_fee";

const showDefaultValue = (fieldType) => fieldType === "hidden";

export default ({ settings, onChangeItem }) => {
  const [item, setItem] = useState(settings);

  const updateItem = (collection) => {
    const newItem = collection.reduce((acc, current) => {
      const { field, value } = current;

      return Object.assign(acc, { [field]: value });
    }, item);

    setItem(newItem);
    onChangeItem(newItem);
  };

  return (
    <div>
      <div className="field-wrapper">
        <SelectMenu
          label="Type"
          options={fieldTypes}
          value={item.type}
          onChange={(value) => {
            if (value === "country") {
              updateItem([
                { field: "type", value: value },
                { field: "name", value: value },
              ]);
            } else if (value === "file") {
              updateItem([
                { field: "type", value: value },
                { field: "options", value: defaultFileOptions },
              ]);
            } else if (value === "cg_atc_fee") {
              updateItem([
                { field: "type", value: value },
                { field: "name", value: value },
              ]);
            } else {
              updateItem([{ field: "type", value: value }]);
            }
          }}
        />
      </div>

      {showName(item.type) && (
        <div className="field-wrapper with-hint">
          <TextInput
            label="Name"
            onChange={(value) => updateItem([{ field: "name", value: value }])}
            value={settings.name}
          />
          <div className="hint">Lowercase and underscores instead spaces</div>
        </div>
      )}

      <label htmlFor="" style={labelStyles}>
        Texts and labels
      </label>

      <div className="fileds-row">
        <div className="field-wrapper">
          <TextInput
            label="Label"
            onChange={(value) => updateItem([{ field: "label", value: value }])}
            value={settings.label}
          />
        </div>

        {showPlaceholder(item.type) && (
          <div className="field-wrapper">
            <TextInput
              label="Placeholder"
              onChange={(value) =>
                updateItem([{ field: "placeholder", value: value }])
              }
              value={settings.placeholder}
            />
          </div>
        )}

        {showHint(item.type) && (
          <div className="field-wrapper">
            <TextInput
              label="Hint"
              onChange={(value) =>
                updateItem([{ field: "hint", value: value }])
              }
              value={settings.hint}
            />
          </div>
        )}
      </div>

      {!showHint(item.type) &&
        !showRequired(item.type) &&
        !showPattern(item.type) &&
        !showPattern(item.type) && (
          <label htmlFor="" style={labelStyles}>
            Validations
          </label>
        )}

      <div className="fileds-row">
        {showRequired(item.type) && (
          <div className="field-wrapper">
            <SelectMenu
              label="Required"
              options={[
                ["yes", "Yes"],
                ["no", "No"],
              ]}
              value={settings.required}
              onChange={(value) =>
                updateItem([{ field: "required", value: value }])
              }
            />
          </div>
        )}

        {showPattern(item.type) && (
          <div className="field-wrapper with-hint">
            <TextInput
              label="Pattern"
              onChange={(value) =>
                updateItem([{ field: "pattern", value: value }])
              }
              value={settings.pattern}
            />
            <div className="hint">Regex</div>
          </div>
        )}

        {showCharacterList(item.type) && (
          <div className="field-wrapper with-hint">
            <TextInput
              label="Character Limit"
              onChange={(value) =>
                updateItem([{ field: "character_limit", value: value }])
              }
              value={settings.character_limit}
            />

            <div className="hint">0-255</div>
          </div>
        )}
      </div>

      <div className="fileds-row">
        {showDefaultValue(item.type) && (
          <div className="field-wrapper">
            <TextInput
              label="Default value"
              onChange={(value) =>
                updateItem([{ field: "default_value", value: value }])
              }
              value={settings.default_value}
            />
          </div>
        )}
      </div>

      <div className="fileds-row">
        {showHint(item.type) && (
          <LinkSettings
            label="Interactive hint"
            onChange={(value) =>
              updateItem([{ field: "interactive_hint", value: value }])
            }
            value={settings.interactive_hint}
          />
        )}
      </div>

      {showSelectOptions(item.type) && (
        <SelectOptions
          onChange={(value) => updateItem([{ field: "options", value: value }])}
          options={settings.options}
        />
      )}

      {showFileOptions(item.type) && (
        <FileOptions
          onChange={(value) => updateItem([{ field: "options", value: value }])}
          options={settings.options}
        />
      )}

      {showCheckboxesOptions(item.type) && (
        <CheckboxesOptions
          onChange={(value) => updateItem([{ field: "options", value: value }])}
          options={settings.options}
        />
      )}
    </div>
  );
};
