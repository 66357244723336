import React from "react";
import { UpsellCampaign } from "@chaos/website-frontend";
import { GeoIpProvider } from "@chaos/website-frontend";

const UpsellCampaignPreview = ({
  currency,
  country_groups,
  geo_ip_country,
  data,
}) => {
  return (
    <GeoIpProvider country={geo_ip_country} allGroups={country_groups}>
      <UpsellCampaign
        currency={currency}
        campaign={data}
        addToCart={(products) => {
          console.log("Adding to cart: ", products);
          alert(
            `Adding to cart:\n${JSON.stringify(
              products.map((uc) => {
                return { id: uc.id, qty: uc.qty, name: uc.details.name };
              }),
              null,
              2
            )}`
          );
        }}
      />
    </GeoIpProvider>
  );
};

export default UpsellCampaignPreview;
