import React from "react";
import { ListInput, LinkSettings } from "@raketa-cms/raketa-cms";
import {
  ImagePicker,
  MediaManagerContext,
} from "@raketa-cms/raketa-image-picker";
import MediaManager from "components/cms/MediaManager";

const mediaManager = new MediaManager("/");

const template = () => ({
  id: 1,
  link: LinkSettings.defaults,
});

const MenuItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

const AccountMenuEditor = ({ name, label, value }) => {
  const [items, setItems] = React.useState(value.length > 0 ? value : []);

  return (
    <MediaManagerContext.Provider value={mediaManager}>
      <div className="field-pannel form-group">
        <label className="control-label">{label}</label>
        <ListInput
          listItem={(settings, onChangeItem) => (
            <MenuItem settings={settings} onChangeItem={onChangeItem} />
          )}
          primaryField="link.label"
          onChangeList={(_, newItems) => setItems(newItems)}
          items={items}
          template={template()}
        />

        <input type="hidden" name={name} value={JSON.stringify(items)} />
      </div>
    </MediaManagerContext.Provider>
  );
};

AccountMenuEditor.defaultProps = {
  label: "Menu items",
};

export default AccountMenuEditor;
