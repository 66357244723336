import React from "react";
import { TextInput, List } from "@raketa-cms/raketa-cms";
import labelStyles from "./labelStyles";

const FileField = ({ settings, onChangeItem }) => (
  <div className="file-field">
    <TextInput
      label="Extension"
      onChange={(value) => onChangeItem("extension", value)}
      value={settings.extension}
      hint="Example: .exe or .pdf"
    />
  </div>
);

export default ({ onChange, options }) => (
  <div className="options-wrapper">
    <label htmlFor="" style={labelStyles}>
      File validations
    </label>

    <TextInput
      label="Max Size (MB)"
      onChange={(maxFileSize) =>
        onChange(Object.assign({}, options, { maxFileSize }))
      }
      value={options.maxFileSize}
    />

    <List
      label="Blacklisted extensions"
      listItem={(settings, onChangeItem) => (
        <FileField settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={(_, types) =>
        onChange(Object.assign({}, options, { types }))
      }
      items={options.types}
      primaryField="extension"
      template={{ extension: ".exe" }}
    />
  </div>
);
