import React from "react";
import Cookie from "../../../Cookie";
import ItlMonitor from "./ItlMonitor";
import { IntentToLeave } from "@chaos/website-frontend";
import { productsInCart, cartIsOpened } from "webshop";

export default function IntentToLeavePopup({ settings, onAccept }) {
  const [alreadyClosed, setAlreadyClosed] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setAlreadyClosed(Cookie.getCookie("itl-closed") === "yes");
  }, []);

  const handleClose = () => {
    Cookie.setCookie("itl-closed", "yes", 5);
    setOpen(false);
    setAlreadyClosed(true);
  };

  if (alreadyClosed) {
    return null;
  }

  return (
    <>
      {open && (
        <IntentToLeave
          intentToLeave={settings}
          onClose={handleClose}
          onAccept={() => {
            handleClose();
            onAccept && onAccept();
          }}
        />
      )}

      <ItlMonitor
        delay={500}
        onLeave={async () => {
          if (alreadyClosed || open) {
            return;
          }

          const hubspotPopups = document.querySelectorAll(
            "[data-hubspot-popup]"
          );

          if (hubspotPopups.length > 0) {
            console.log("Custom popup is present!");
            return;
          }

          const nativeHubspotPopups = document.querySelectorAll(
            "[id^='hs-overlay-cta-']"
          );

          if (
            nativeHubspotPopups.length > 0 &&
            Array.from(nativeHubspotPopups).every((el) =>
              el.checkVisibility({ visibilityProperty: true })
            )
          ) {
            console.log("Native popup is present!");
            return;
          }

          const upsellCampaign = document.getElementById("upsell-campaign");

          if (upsellCampaign) {
            console.log("Upsell campaign active!");
            return;
          }

          const isOpened = await cartIsOpened();
          const count = await productsInCart();

          if (count > 0 && !isOpened) {
            setOpen(true);
          }
        }}
      />
    </>
  );
}
